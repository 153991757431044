import moment from "moment"

export default function format_date(value,format_tanggal) {
    if (value) {
      return moment(String(value)).format(format_tanggal)
     }
}

// export default{
//     format_date
// }