<template>
  <div class="card card-custom gutter-b p-5">
    <div class="py-2">
      <b-button class="float-right" variant="success" @click="showModal"
        >Tambah Kegiatan</b-button
      >
    </div>
    <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
      <table
        id="example"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width: 20px">No</th>
            <th style="width: 200px">Nama Kegiatan</th>
            <th>Waktu</th>
            <th>Materi</th>
            <!-- <th>Deskripsi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <!-- diubah sesuai kebutuhan tabel -->
          <tr v-for="(row, index) in kegiatan" :key="index">
            <td></td>
            <td>{{ row.nama_kegiatan }}</td>
            <td>
              {{
                format_tanggal(row.tanggal_mulai, "D MMM YYYY") +
                " s.d. " +
                format_tanggal(row.tanggal_selesai, "D MMM YYYY")
              }}
            </td>
            <td>
              <ul class="px-5">
                <li v-for="(data, i) in row.materi" :key="i">
                  {{ data.judul }}
                </li>
              </ul>
            </td>
            <!-- <td></td> -->
            <td>
              <b-button
                @click="$router.push('/kegiatanbmtk/pendaftar/' + row.id)"
                size="sm"
                variant="primary"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Pendaftar"
                ><i class="fa fa-user-plus" style=""></i
              ></b-button>
              <b-button
                @click="$router.push('/kegiatanbmtk/peserta/' + row.id)"
                size="sm"
                variant="success"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Daftar Peserta"
                ><i class="flaticon-users"></i
              ></b-button>
              <b-button
                @click="getdataSoal(row.id)"
                size="sm"
                variant="primary"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Daftar Soal"
                ><i class="flaticon-list"></i
              ></b-button>
              <b-button
                @click="lihat(row.id)"
                size="sm"
                variant="warning"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Ubah Kegiatan"
                ><i class="flaticon-edit"></i
              ></b-button>
              <b-button
                class="m-1 pr-2"
                @click="deleteKegiatan(row.id)"
                size="sm"
                variant="danger"
                v-b-tooltip.hover
                title="Hapus Kegiatan"
                ><i class="flaticon2-trash"></i
              ></b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal size="xl" id="modal-add" title="Tambah Kegiatan" hide-footer>
      <div class="form-group">
        <label for="namaKegiatan">Nama Kegiatan</label>
        <input
          type="text"
          class="form-control"
          id="namaKegiatan"
          v-model="form.nama_kegiatan"
        />
      </div>
      <div class="form-group row">
        <div class="col">
          <label for="waktuMulaiKegiatan">Tanggal Mulai Kegiatan</label>
          <div>
            <date-picker
              v-model="form.tanggal_mulai"
              format="DD-MM-YYYY"
              type="date"
              style="width: 100%"
              value-type="YYYY-MM-DD"
              placeholder="Select date"
            ></date-picker>
          </div>

          <!-- <b-form-datepicker
            v-model="form.tanggal_mulai"
            locale="in"
            class="mb-2"
          ></b-form-datepicker> -->
        </div>
        <div class="col">
          <label for="waktuSelesaiKegiatan">Tanggal Selesai Kegiatan</label>
          <div>
            <date-picker
              v-model="form.tanggal_selesai"
              format="DD-MM-YYYY"
              type="date"
              value-type="YYYY-MM-DD"
              style="width: 100%"
              placeholder="Select date"
            ></date-picker>
          </div>
          <!-- <b-form-datepicker
            v-model="form.tanggal_selesai"
            locale="in"
            class="mb-2"
          ></b-form-datepicker> -->
        </div>
      </div>
      <div class="form-group">
        <label for="provinsi">Pilih Provinsi</label>
        <s-select
          :options="provinsi"
          multiple
          :reduce="(data) => data.id"
          v-model="form.id_provinsi"
          label="name"
          placeholder="Pilih Provinsi"
        ></s-select>
      </div>
      <div class="form-group">
        <label for="materi">Pilih Materi</label>
        <s-select
          :options="materi"
          multiple
          v-model="form.id_materi"
          :reduce="(data) => data.id"
          label="judul"
          placeholder="Pilih Materi"
        ></s-select>
      </div>
      <div class="form-group">
        <label for="jumlahSoal">Jumlah Butir Soal</label>
        <input
          type="text"
          class="form-control"
          id="jumlahSoal"
          v-model="form.jumlah_soal"
        />
      </div>
      <div class="form-group">
        <label for="waktuMulaiKegiatan">Waktu Pre-test</label>
        <div class="row">
          <div class="col-md-12">
            <date-picker
              v-model="waktu_pretest"
              type="datetime"
              format="YYYY-MM-DD HH:mm:ss"
              class="mb-2"
              style="width: 100%"
              range
              placeholder="Select datetime range"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="waktuMulaiKegiatan">Waktu Post-test</label>
        <!-- {{ form.waktu_test }} -->
        <div class="row">
          <div class="col-md-12">
            <date-picker
              v-model="waktu_posttest"
              type="datetime"
              class="mb-2"
              style="width: 100%"
              range
              placeholder="Select datetime range"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="tags-basic">Mata Ajar</label>
        <b-form-tags
          input-id="tags-basic"
          v-model="form.mata_ajar"
        ></b-form-tags>
      </div>
      <div>
        <legend>Tempat Pelaksanaan Kegiatan</legend>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="provinsi">Provinsi</label>
              <s-select
                :options="provinsi"
                :reduce="(data) => data.name"
                v-model="form.provinsi_pelaksanaan"
                @input="getKabupaten()"
                label="name"
                placeholder="Pilih Provinsi"
              ></s-select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="provinsi">Kabupaten/Kota</label>
              <s-select
                :options="dataKabupaten"
                :reduce="(data) => data.name"
                v-model="form.kota_pelaksanaan"
                label="name"
                placeholder="Pilih Kabupaten/Kota"
              ></s-select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-button class="float-right" variant="primary" @click="submitKegiatan">
          Simpan
        </b-button>
        <b-button
          class="float-right mr-2"
          variant="danger"
          @click="$bvModal.hide('modal-add')"
        >
          Batal
        </b-button>
      </div>
    </b-modal>
    <b-modal size="xl" id="modal-edit" title="Edit Kegiatan" hide-footer>
      <div class="form-group">
        <label for="namaKegiatan">Nama Kegiatan</label>
        <input
          type="text"
          class="form-control"
          id="namaKegiatan"
          v-model="form.nama_kegiatan"
        />
      </div>
      <div class="form-group row">
        <div class="col">
          <label for="waktuMulaiKegiatan">Tanggal Mulai Kegiatan</label>
          <div>
            <date-picker
              v-model="form.tanggal_mulai"
              format="DD-MM-YYYY"
              type="date"
              style="width: 100%"
              value-type="YYYY-MM-DD"
              placeholder="Select date"
            ></date-picker>
          </div>

          <!-- <b-form-datepicker
            v-model="form.tanggal_mulai"
            locale="in"
            class="mb-2"
          ></b-form-datepicker> -->
        </div>
        <div class="col">
          <label for="waktuSelesaiKegiatan">Tanggal Selesai Kegiatan</label>
          <div>
            <date-picker
              v-model="form.tanggal_selesai"
              format="DD-MM-YYYY"
              type="date"
              value-type="YYYY-MM-DD"
              style="width: 100%"
              placeholder="Select date"
            ></date-picker>
          </div>
          <!-- <b-form-datepicker
            v-model="form.tanggal_selesai"
            locale="in"
            class="mb-2"
          ></b-form-datepicker> -->
        </div>
      </div>
      <div class="form-group">
        <label for="provinsi">Pilih Provinsi</label>
        <s-select
          :options="provinsi"
          multiple
          :reduce="(data) => data.id"
          v-model="form.id_provinsi"
          label="name"
          placeholder="Pilih Provinsi"
        ></s-select>
      </div>
      <div class="form-group">
        <label for="materi">Pilih Materi</label>
        <s-select
          :options="materi"
          multiple
          v-model="form.id_materi"
          :reduce="(data) => data.id"
          label="judul"
          placeholder="Pilih Materi"
        ></s-select>
      </div>
      <div class="form-group">
        <label for="jumlahSoal">Jumlah Butir Soal</label>
        <input
          type="text"
          class="form-control"
          id="jumlahSoal"
          v-model="form.jumlah_soal"
        />
      </div>
      <div class="form-group">
        <label for="waktuMulaiKegiatan">Waktu Pre-test</label>
        <div class="row">
          <div class="col-md-12">
            <date-picker
              v-model="waktu_pretest"
              type="datetime"
              format="YYYY-MM-DD HH:mm:ss"
              class="mb-2"
              style="width: 100%"
              range
              placeholder="Select datetime range"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="waktuMulaiKegiatan">Waktu Post-test</label>
        <!-- {{ form.waktu_test }} -->
        <div class="row">
          <div class="col-md-12">
            <date-picker
              v-model="waktu_posttest"
              type="datetime"
              class="mb-2"
              style="width: 100%"
              range
              placeholder="Select datetime range"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="tags-basic">Mata Ajar</label>
        <b-form-tags
          input-id="tags-basic"
          v-model="form.mata_ajar"
        ></b-form-tags>
      </div>
      <div>
        <legend>Tempat Pelaksanaan Kegiatan</legend>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="provinsi">Provinsi</label>
              <s-select
                :options="provinsi"
                :reduce="(data) => data.name"
                v-model="form.provinsi_pelaksanaan"
                @input="getKabupaten()"
                label="name"
                placeholder="Pilih Provinsi"
              ></s-select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="provinsi">Kabupaten/Kota</label>
              <s-select
                :options="dataKabupaten"
                :reduce="(data) => data.name"
                v-model="form.kota_pelaksanaan"
                label="name"
                placeholder="Pilih Kabupaten/Kota"
              ></s-select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-button class="float-right" variant="primary" @click="submitKegiatan">
          Simpan
        </b-button>
        <b-button
          class="float-right mr-2"
          variant="danger"
          @click="$bvModal.hide('modal-edit')"
        >
          Batal
        </b-button>
      </div>
    </b-modal>

    <b-modal id="modal-soal" title="Daftar Soal" hide-footer size="xl">
      <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
        <table
          id="table-soal"
          class="table table-striped table-bordered"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th style="width: 20px">No</th>
              <th style="width: 200px">Pertanyaan</th>
              <th>Opsi A</th>
              <th>Opsi B</th>
              <th>Opsi C</th>
              <th>Opsi D</th>
              <th>Opsi E</th>
              <th>Jawaban Benar</th>
            </tr>
          </thead>
          <tbody>
            <!-- diubah sesuai kebutuhan tabel -->
            <tr v-for="(row, index) in dataSoal" :key="index">
              <td></td>
              <td>{{ row.soal }}</td>
              <td>
                {{ row.opsi_a }}
              </td>
              <td>
                {{ row.opsi_b }}
              </td>
              <td>
                {{ row.opsi_c }}
              </td>
              <td>
                {{ row.opsi_d }}
              </td>
              <td>
                {{ row.opsi_e }}
              </td>
              <td>
                {{ row.jawaban_benar }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-button
          class="float-right"
          variant="danger"
          @click="$bvModal.hide('modal-soal')"
        >
          Tutup
        </b-button>
        <b-button
          class="float-right mr-2"
          variant="primary"
          @click="generateSoal"
        >
          Generate Soal
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import format_date from "@/core/utils/helper";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Kegiatan",
  components: {
    "s-select": VueSelect,
    format_date,
    DatePicker,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      kegiatan: [],
      provinsi: [],
      materi: [],
      dataSoal: [],
      role: "admin_bimtek",
      form: {},
      id_kegiatan: null,
      dataKabupaten: [],
      waktu_pretest: [],
      waktu_posttest: [],
    };
  },
  methods: {
    getdata() {
      $("#example").DataTable().destroy();
      this.loaddata();
    },
    getdataSoal(id) {
      $("#table-soal").DataTable().destroy();

      this.lihatSoal(id);
      this.$bvModal.show("modal-soal");
      this.id_kegiatan = id;
    },
    format_tanggal(value, format) {
      return format_date(value, format);
    },
    loaddata() {
      this.axios
        .get(this.url + "/jadwal_kegiatan", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kegiatan = response.data.data;
          this.initDatatable();
        });
    },

    loadMateri() {
      this.axios
        .get(this.url + "/materi_pelatihan", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.materi = response.data.data;
        });
    },

    loadProvinsi() {
      this.axios
        .get(this.url + "/provinsi", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.provinsi = response.data.data;
        });
    },

    getKabupaten() {
      this.dataKabupaten = [];

      this.axios
        .get(this.url + "/kabkota/" + this.form.provinsi_pelaksanaan, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataKabupaten = response.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },

    showModal() {
      this.$bvModal.show("modal-add");
    },
    submitKegiatan() {
      var m = this.waktu_pretest[0];
      var s = this.waktu_pretest[1];
      this.form.waktu_awal_pretest =
        m.getFullYear() +
        "-" +
        (m.getMonth() + 1) +
        "-" +
        m.getDate() +
        " " +
        m.getHours() +
        ":" +
        m.getMinutes() +
        ":" +
        m.getSeconds();
      this.form.waktu_akhir_pretest =
        s.getFullYear() +
        "-" +
        (s.getMonth() + 1) +
        "-" +
        s.getDate() +
        " " +
        s.getHours() +
        ":" +
        s.getMinutes() +
        ":" +
        s.getSeconds();

      var mpo = this.waktu_posttest[0];
      var spo = this.waktu_posttest[1];
      this.form.waktu_awal_posttest =
        mpo.getFullYear() +
        "-" +
        (mpo.getMonth() + 1) +
        "-" +
        mpo.getDate() +
        " " +
        mpo.getHours() +
        ":" +
        mpo.getMinutes() +
        ":" +
        mpo.getSeconds();
      this.form.waktu_akhir_posttest =
        spo.getFullYear() +
        "-" +
        (spo.getMonth() + 1) +
        "-" +
        spo.getDate() +
        " " +
        spo.getHours() +
        ":" +
        spo.getMinutes() +
        ":" +
        spo.getSeconds();

      if (this.form.id == null) {
        this.axios
          .post(this.url + "/jadwal_kegiatan", this.form, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.$bvModal.hide("modal-add");
            this.form = {};
            this.getdata();
          });
      } else {
        this.axios
          .put(this.url + "/jadwal_kegiatan/" + this.form.id, this.form, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.$bvModal.hide("modal-edit");
            this.form = {};
            this.getdata();
          });
      }
    },
    lihatSoal(id) {
      this.dataSoal = [];
      this.axios
        .get(this.url + "/soal_ujian?filter=id_kegiatan,=," + id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataSoal = response.data.data;

          this.initDatatableSoal();
        });
    },
    lihat(id) {
      const dataKegiatan = this.kegiatan.find((p) => p.id === id);
      if (dataKegiatan) {
        // Update the form with the data

        this.form = {
          id: dataKegiatan.id,
          nama_kegiatan: dataKegiatan.nama_kegiatan,
          tanggal_mulai: dataKegiatan.tanggal_mulai,
          tanggal_selesai: dataKegiatan.tanggal_selesai,
          id_provinsi: [],
          id_materi: [],
          jumlah_soal: dataKegiatan.jumlah_soal,
          kota_pelaksanaan: dataKegiatan.kota_pelaksanaan,
          provinsi_pelaksanaan: dataKegiatan.provinsi_pelaksanaan,
          mata_ajar: dataKegiatan.mata_ajar,
        };

        const date_mp = new Date(dataKegiatan.waktu_awal_pretest);
        const date_sp = new Date(dataKegiatan.waktu_akhir_pretest);

        this.waktu_pretest[0] = new Date(
          date_mp.getFullYear(),
          date_mp.getMonth(),
          date_mp.getDate(),
          date_mp.getHours(),
          date_mp.getMinutes(),
          date_mp.getSeconds()
        );
        this.waktu_pretest[1] = new Date(
          date_sp.getFullYear(),
          date_sp.getMonth(),
          date_sp.getDate(),
          date_sp.getHours(),
          date_sp.getMinutes(),
          date_sp.getSeconds()
        );

        const date_mpo = new Date(dataKegiatan.waktu_awal_posttest);
        const date_spo = new Date(dataKegiatan.waktu_akhir_posttest);
        this.waktu_posttest[0] = new Date(
          date_mpo.getFullYear(),
          date_mpo.getMonth(),
          date_mpo.getDate(),
          date_mpo.getHours(),
          date_mpo.getMinutes(),
          date_mpo.getSeconds()
        );
        this.waktu_posttest[1] = new Date(
          date_spo.getFullYear(),
          date_spo.getMonth(),
          date_spo.getDate(),
          date_spo.getHours(),
          date_spo.getMinutes(),
          date_spo.getSeconds()
        );

        // for (let i = 0; i < length; i++){
        //   // Instead of parseInt(), Number()
        //       // can also be used
        //       this.form.id_provinsi.push(parseInt(dataKegiatan.id_provinsi[i]));
        // }

        dataKegiatan.id_provinsi.forEach((ele) =>
          this.form.id_provinsi.push(+ele)
        );

        dataKegiatan.id_materi.forEach((ele) => this.form.id_materi.push(+ele));

        this.getKabupaten();
      }
      this.$bvModal.show("modal-edit");
    },

    generateSoal() {
      this.axios
        .get(this.url + "/jadwal_kegiatan/generate_soal/" + this.id_kegiatan, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          $("#table-soal").DataTable().destroy();
          this.lihatSoal(this.id_kegiatan);
        });
    },
    deleteKegiatan(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(this.url + "/jadwal_kegiatan/" + id, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Terhapus!",
                text: "Data berhasil dihapus",
                icon: "success",
              });
              this.getdata();
            });
        }
      });
    },

    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
    initDatatableSoal() {
      setTimeout(() => {
        var t = $("#table-soal").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
  },
  mounted() {
    this.getdata();
    this.loadMateri();
    this.loadProvinsi();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kegiatan" }]);
  },
};
</script>